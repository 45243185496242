import { combineReducers, configureStore } from '@reduxjs/toolkit';
import * as user from './Reducers/UserReducer';
import * as cat from './Reducers/CatReducer';
import * as prod from './Reducers/ProductReducer';
import * as order from './Reducers/OrderReducer';

const rootReducer = combineReducers({
  // user reducers
  userLogin: user.LoginReducer,
  userRegister: user.RegisterReducer,
  updateProfile: user.UpdateProfileReducer,
  changePassword: user.ChangePasswordReducer,
  deleteAccount: user.DeleteAccountReducer,
  // category reducers
  categoriesList: cat.getCategories,
  // product reducers
  productList: prod.ProductListReducer,
  singleProduct: prod.GetSingleProductReducer,
  tagsProduct: prod.TagsProductReducer,
  // cart reducers
  cart: prod.CartReducer,
  // order reducers
  createOrder: order.createOrderReducer,
  getAllOrders: order.getAllOrdersReducer,
  getOrderById: order.getOrderByIdReducer,
  deleteOrder: order.deleteOrderReducer,
  deleteAllOrders: order.deleteAllOrdersReducer,
  // stripe reducers
  stripeCheckout: order.stripeCheckoutReducer,
});

// get userInfo from localStorage
const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

// get cartItems from localStorage
const cartItemsFromStorage = localStorage.getItem('cart')
  ? JSON.parse(localStorage.getItem('cart'))
  : [];

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  cart: { cartItems: cartItemsFromStorage },
};

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});
