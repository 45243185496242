import { ScaleLoader } from "react-spinners";
import "./loader.css";

function BigLoader() {
  return (
    <div className="w-full py-4 px-2 flex-colo h-screen">
      {/* <ScaleLoader color="#ed4c07" /> */}
      <div class="preloader">
        <svg
          class="cart"
          role="img"
          aria-label="Shopping cart line animation"
          viewBox="0 0 128 128"
          width="128px"
          height="128px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="8"
          >
            <g class="cart__track" >
              <polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
              <circle cx="43" cy="111" r="13" />
              <circle cx="102" cy="111" r="13" />
            </g>
            <g class="cart__lines" stroke="currentColor">
              <polyline
                class="cart__top"
                points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
                stroke-dasharray="338 338"
                stroke-dashoffset="-338"
              />
              <g class="cart__wheel1" transform="rotate(-90,43,111)">
                <circle
                  class="cart__wheel-stroke"
                  cx="43"
                  cy="111"
                  r="13"
                  stroke-dasharray="81.68 81.68"
                  stroke-dashoffset="81.68"
                />
              </g>
              <g class="cart__wheel2" transform="rotate(90,102,111)">
                <circle
                  class="cart__wheel-stroke"
                  cx="102"
                  cy="111"
                  r="13"
                  stroke-dasharray="81.68 81.68"
                  stroke-dashoffset="81.68"
                />
              </g>
            </g>
          </g>
        </svg>
        <h5 className="mt-2 font-bold text-3xl text-uppercase">
          Viet E-mart loading....
        </h5>
        <p className="mt-2">E-Asian Grocery Powered by AI</p>
      </div>
    </div>
  );
}

export default BigLoader;
