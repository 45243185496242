import * as Types from '../Constants/AllConstants';

// get all products
export const ProductListReducer = (
  state = { products: [], offers: [] },
  action
) => {
  switch (action.type) {
    case Types.PRODUCT_LIST_REQUEST:
      return { loading: true };
    case Types.PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
        offers: action.payload.offers,
      };
    case Types.PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    case Types.PRODUCT_LIST_RESET:
      return { products: [], offers: [] };
    default:
      return state;
  }
};

// get single product
export const GetSingleProductReducer = (
  state = {
    product: {},
    related: [],
  },
  action
) => {
  switch (action.type) {
    case Types.GET_PRODUCT_REQUEST:
      return { loading: true, product: {} };
    case Types.GET_PRODUCT_SUCCESS:
      return {
        loading: false,
        product: action.payload.product,
        related: action.payload.relatedProducts,
      };
    case Types.GET_PRODUCT_FAIL:
      return { loading: false, error: action.payload };
    case Types.GET_PRODUCT_RESET:
      return {
        product: {},
        related: [],
      };
    default:
      return state;
  }
};

// get all popular tags
export const TagsProductReducer = (state = { tags: [] }, action) => {
  switch (action.type) {
    case Types.TAGS_PRODUCT:
      return { tags: action.payload };
    case Types.TAGS_PRODUCT_FAIL:
      return { error: action.payload };
    case Types.TAGS_PRODUCT_RESET:
      return { tags: [] };
    default:
      return state;
  }
};

// cart reducer
export const CartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case Types.CART_ADD_ITEM:
      return {
        cartItems: [...state.cartItems, action.payload],
      };
    case Types.CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x._id !== action.payload),
      };
    case Types.CART_RESET:
      return { cartItems: [] };
    default:
      return state;
  }
};
